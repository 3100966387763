<template>
  <div>
    <vx-card class="mb-base">
      <div class="vx-row mb-5">
        <div class="vx-col md:w-1/2 w-1/2">
          <h2 class="mb-0">Past Subscription Details</h2>
        </div>
      </div>

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full pr-5 mb-5">
            <label class="font-bold">Subscription Name:</label>
            <p>{{ currentContents.subscriptionPackageDetails[0].name | capitalize }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full pl-5 mb-5">
            <label class="font-bold">Price:</label>
            <p>$ {{ currentContents.price }}/child/month</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full pr-5 mb-5">
            <label class="font-bold">Purchased At:</label>
            <p>{{ moment(currentContents.purchasedAt).format('DD/MM/YYYY') }}</p>
          </div>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-bold">Start Date:</label>
            <p>{{ moment(currentContents.startDate).format('DD/MM/YYYY') }}</p>
          </div>
        </vs-col>-->
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full pl-5 mb-5">
            <label class="font-bold">Cancelled At:</label>
            <p>{{ moment(currentContents.candelledAt).format('DD/MM/YYYY') }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full pr-5 mb-5">
            <label class="font-bold">Billing Started At:</label>
            <p>{{ moment(currentContents.billingStartedAt).format('DD/MM/YYYY') }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full pl-5 mb-5">
            <label class="font-bold">Number of Centre:</label>
            <p>{{ currentContents.numberOfCenter }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full pr-5 mb-5">
            <label class="font-bold">Number of Children:</label>
            <p>{{ currentContents.numberOfChildren }}</p>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <vx-card class="mb-base directors-card">
      <div v-if="renews.length > 0">
        <vs-table max-items="5" pagination :data="renews">
          <div slot="header" class="w-full">
            <div class="mb-8">
              <h2 class="mb-0">Renew Details</h2>
            </div>
          </div>
          <template slot="thead">
            <vs-th>Renewed Date</vs-th>
            <vs-th>End Date</vs-th>
            <vs-th>Price</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td
                :data="data[indextr].renewedDate"
              >{{ moment(data[indextr].renewedDate).format('DD/MM/YYYY') }}</vs-td>
              <vs-td
                :data="data[indextr].endDate"
              >{{ moment(data[indextr].endDate).format('DD/MM/YYYY') }}</vs-td>
              <vs-td :data="data[indextr].price">${{ data[indextr].price }}/child/month</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <div v-else>
        <h6>No renew details available</h6>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      currentContents: [],
      renews: [],
    };
  },
  methods: {
    ...mapActions("setting", ["fetchPastSubscriptionDetails"]),
    moment(date) {
      return moment(date);
    },
    getPastSubscriptionDetails(id) {
      this.fetchPastSubscriptionDetails(id).then((res) => {
        this.currentContents = res.data.data[0];
        this.renews = this.currentContents.renews;
      });
    },
  },
  created() {
    let id = this.$route.params.id;
    this.getPastSubscriptionDetails(id);
  },
};
</script>
